<template>
  <el-container>
    <!-- <el-header> </el-header> -->
    
    <el-main class="back-img">
        
      <div class="login-logo">
        <!-- <div>天津大学</div> -->
        <div>城市大厦</div>
        <hr>
        <div>消防物联网监控平台</div>
        <!-- <el-image :src="require('@/assets/logo1.png')"> </el-image> -->
      </div>
      <div class="login-form">
        <div class="login_title">欢迎登录</div>
        <el-form
          class="mtb20"
          :model="login_form"
          :rules="rules"
          status-icon
          ref="login_form"
        >
          <el-form-item prop="userName">
            <el-input
              v-model="login_form.userName"
              prefix-icon="el-icon-user"
              size="medium"
              placeholder="请输入用户名"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="userPsw">
            <el-input
              v-model="login_form.userPsw"
              prefix-icon="el-icon-lock"
              size="medium"
              placeholder="请输入密码"
              clearable
              show-password
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <div class="login-box">
              <a @click="loginIn('login_form')">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                登录
              </a>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import userApi from "@/api/manageApi/User.js";
import cookie from 'js-cookie';
export default {
  name: "Login",
  components: {},
  data() {
    return {
      loading: false,
      login_form: {
        userName: "",
        userPsw: "",
      },
      rules: {
        userName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 3,
            max: 11,
            message: "长度在 3 到 11 个字符",
            trigger: "blur",
          },
        ],
        userPsw: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 3,
            max: 16,
            message: "长度在 3 到 16 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    //登录
    loginIn(login_form) {
      this.$refs[login_form].validate((valid) => {
          if (valid) {
            userApi
                    .login(this.login_form.userName, this.login_form.userPsw)
                    .then((res) => {
                      if (res.data.code == 20000) {


                        this.$router.push("/select");
                        this.$message({
                          message: "登陆成功",
                          type: "success",
                          duration: 1500,
                        });
                      } else {
                        this.$message.error("登录失败");
                        return false;
                      }
                    });
          }
      });
    },
  },
  created() {
    // 按回车键登录
    var _self = this;
    document.onkeydown = function (e) {
      var key = window.event.keyCode;
      if (key == 13) {
        _self.loginIn("login_form");
      }
    };
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.login-logo {
  font-size:10vh;
  font-weight: 700;
  color: #fff;
  text-shadow: 5px 5px 5
  px #000;
  font-family: "华文中宋";
  position: absolute;
  top: 20%;
  left: 5%;
}
.login-logo div{
  line-height: 15vh;
  text-shadow: 5px 5px 5px #000;
}
/deep/ .el-image {
  transform: scale(1, 1);
  cursor: pointer;
}
.el-container {
  height: 100vh;
}

/deep/ .login-form {
  width: 310px;
  height: 350px;
  font-family: "Times New Roman", Times, serif;
  position: absolute;
  top: 50%;
  left: 72%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
}
.el-form-item {
  margin-bottom: 30px;
}
/deep/ .el-form-item__label {
  color: #fff;
}
/deep/ .login_title {
  text-align: center;
  color: #fff;
  font-weight: 500;
  font-size: 30px;
  margin: 50px 0 10px 0;
  cursor: default;
}
.mtb20 {
  display: inline-block;
  margin-top: 22px;
  position: relative;
  left: 50%;
  transform: translate(-50%);
}
.w-135 {
  width: 145px;
}
.el-button--primary {
  color: #fff;
  background-color: #001e5c;
}
/deep/ .el-form-item__content {
  text-align: center;
}
.el-button {
  width: 100%;
}
</style>